import React from 'react';
import {PropTypes} from 'prop-types';

import ValidationErrors from 'services/ValidationErrors';

import {FormGroup, Label} from 'reactstrap';
import EditButtonModal from 'sharedComponents/buttons/editButtonModal/EditButtonModal';
import Input from 'sharedComponents/input/Input';

export default class EditButton extends React.PureComponent {
  static propTypes = {
    ruleAssociation: PropTypes.object.isRequired,
    ruleModel: PropTypes.object.isRequired,
    treatmentMode: PropTypes.object,
    ambulisProtocol: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  };

  getValidations = (param) => {
    switch (param.type) {
      case 'hour':
        return {
          matchRegexp: /([01][0-9]|2[0-3]):[0-5][0-9]/,
        };

      case 'integer':
        return 'isInt';

      default:
        return null;
    }
  };

  getValue = (param) => {
    const associationValue = this.props.ruleAssociation.params.find(
      (p) => p.code === param.code,
    );
    return associationValue.value;
  };

  render() {
    const {ruleAssociation, ruleModel, treatmentMode, ambulisProtocol} =
      this.props;

    if (!ruleAssociation.params || ruleAssociation.params.length === 0) {
      return null;
    }

    return (
      <EditButtonModal
        data={ruleAssociation}
        title="Modifier une règle de gestion"
        tooltip="Modifier une règle de gestion"
        onSubmit={this.props.onSubmit}>
        <div>
          <FormGroup>
            <Label>Mode de traitement</Label>
            <div className="ps-3">
              {treatmentMode ? treatmentMode.name : 'Tous'}
            </div>
          </FormGroup>

          <FormGroup>
            <Label>Protocole Ambulis</Label>
            <div className="ps-3">
              {ambulisProtocol ? ambulisProtocol.name : 'Tous'}
            </div>
          </FormGroup>

          <FormGroup>
            <Label>Règle de gestion</Label>
            <div className="ps-3">{ruleModel.title}</div>
          </FormGroup>

          {ruleModel.params && ruleModel.params.length > 0 && (
            <>
              <hr />
              {ruleModel.params.map((param) => (
                <FormGroup className="required">
                  <Label>{param.title}</Label>
                  <Input
                    value={this.getValue(param)}
                    type="text"
                    name={`params[${param.code}]`}
                    validations={this.getValidations(param)}
                    validationErrors={ValidationErrors}
                    required
                  />
                </FormGroup>
              ))}
            </>
          )}
        </div>
      </EditButtonModal>
    );
  }
}
