import ApiClient from 'admin/services/ApiClient';

export default class ManagementRulesApi {
  static getModels() {
    return ApiClient.get('managementRules/models');
  }

  static add = (clinicId, data) =>
    ApiClient.post(`clinics/${clinicId}/managementRules`, data);

  static getForClinic = (clinicId) =>
    ApiClient.get(`clinics/${clinicId}/managementRules`);

  static deleteAssociation = (clinicId, externalId) =>
    ApiClient.delete(`clinics/${clinicId}/managementRules/${externalId}`);

  static editAssociation = (clinicId, externalId, data) =>
    ApiClient.patch(`clinics/${clinicId}/managementRules/${externalId}`, data);
}
