import React, { PureComponent } from 'react';
import {PropTypes} from 'prop-types';

export default class ParamsDetails extends React.PureComponent {
  static propTypes = {
    ruleAssociation: PropTypes.object.isRequired,
    ruleModel: PropTypes.object.isRequired,
  };

  render() {
    const {ruleAssociation, ruleModel} = this.props;

    if (!ruleAssociation.params || ruleAssociation.params.length === 0) {
      return null;
    }

    return (
      <ul>
        {ruleAssociation.params.map((param) => {
          let paramDetails  = ruleModel.params.find(p => p.code === param.code);

          return (
            <li key={param.code}>
              {paramDetails.title} : {param.value}
            </li>
          );
        })}
      </ul>
    );
  }
}
