import React from 'react';
import {PropTypes} from 'prop-types';
import {FormGroup, Label} from 'reactstrap';
import Input from 'sharedComponents/input/Input';
import Select from 'sharedComponents/select/Select';
import ValidationErrors from 'services/ValidationErrors';

export default class CreateForm extends React.PureComponent {
  static propTypes = {
    treatmentModes: PropTypes.array.isRequired,
    protocols: PropTypes.array.isRequired,
    managementRules: PropTypes.array.isRequired,
  };

  state = {
    selectedManagementRule: null,
  };

  onSelectRule = (data) => {
    const rule = this.props.managementRules.find((r) => r.code === data);
    this.setState({
      selectedManagementRule: rule,
    });
  };

  getValidations = (param) => {
    switch (param.type) {
      case 'hour':
        return {
          matchRegexp: /([01][0-9]|2[0-3]):[0-5][0-9]/,
        };

      case 'integer':
        return 'isInt';

      default:
        return null;
    }
  };

  render() {
    const treatmentModesOptions = this.props.treatmentModes.map((tm) => ({
      label: tm.name,
      value: tm.mdtCode,
    }));

    const protocolsOptions = this.props.protocols.map((p) => ({
      label: p.name,
      value: p.id,
    }));

    const managementRulesOptions = this.props.managementRules.map((r) => ({
      label: r.title,
      value: r.code,
    }));

    return (
      <div className="userForm">
        <FormGroup>
          <Label>Mode de traitement</Label>
          <Select name="mdtCode" options={treatmentModesOptions} />
        </FormGroup>

        <FormGroup>
          <Label>Protocole Ambulis</Label>
          <Select name="ambulisOperatingMethodId" options={protocolsOptions} />
        </FormGroup>

        <FormGroup className="required">
          <Label>Règle de gestion</Label>
          <Select
            name="ruleCode"
            options={managementRulesOptions}
            required
            onChange={this.onSelectRule}
          />
        </FormGroup>

        {this.state.selectedManagementRule &&
          this.state.selectedManagementRule.params.length > 0 && (
            <>
              <hr />
              {this.state.selectedManagementRule.params.map((param) => (
                <FormGroup className="required">
                  <Label>{param.title}</Label>
                  <Input
                    type="text"
                    name={`params[${param.code}]`}
                    validations={this.getValidations(param)}
                    validationErrors={ValidationErrors}
                    required
                  />
                </FormGroup>
              ))}
            </>
          )}
      </div>
    );
  }
}
