import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {NotificationManager} from 'react-notifications';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';

import {FormGroup, Table} from 'reactstrap';
import CreateButtonModal from 'sharedComponents/buttons/createButtonModal/CreateButtonModal';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import CreateForm from './createForm/CreateForm';
import ParamsDetails from './paramsDetails/ParamsDetails';
import EditButton from './editButton/EditButton';

import ClinicsApi from 'admin/apis/clinics.api';
import ManagementRulesApi from 'admin/apis/managementRules.api';

export default class ManagementRulesTab extends React.PureComponent {
  static propTypes = {
    clinicId: PropTypes.string.isRequired,
    layoutContextProvider: PropTypes.object.isRequired,
  };

  state = {
    isLoading: true,
    loadingError: false,
    protocols: null,
    treatmentModes: null,
    ruleModels: null,
    managementRules: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {
        isLoading: true,
        loadingError: false,
        protocols: null,
        treatmentModes: null,
        managementRules: null,
        ruleModels: null,
      },
      async () => {
        let nextState = {isLoading: false};

        const data = await Promise.all([
          ClinicsApi.getProtocols(this.props.clinicId),
          ClinicsApi.getTreatmentModes(this.props.clinicId),
          ManagementRulesApi.getModels(),
          ManagementRulesApi.getForClinic(this.props.clinicId),
        ]).catch((error) => {
          nextState.loadingError = true;
          return null;
        });

        if (data) {
          nextState.protocols = _.sortBy(data[0], 'id');
          nextState.treatmentModes = data[1];
          nextState.ruleModels = data[2];
          nextState.managementRules = _.sortBy(data[3], [
            function (item) {
              return item.mdtCode === null ? 0 : 1;
            },
            function (item) {
              return item.ambulisOperatingMethodId === null ? 0 : 1;
            },
          ]);
        }

        this.setState(nextState);
      },
    );
  };

  saveAddModal = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await ManagementRulesApi.add(this.props.clinicId, formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success(
      'La règle de gestion a été ajoutée avec succès',
      null,
    );
    this.loadData();
    return Promise.resolve();
  };

  saveEditModal = async (ruleAssociation, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = false;

    await ManagementRulesApi.editAssociation(
      this.props.clinicId,
      ruleAssociation.externalId,
      formData,
    ).catch((e) => {
      error = true;
    });

    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    }

    NotificationManager.success(
      'La règle de gestion a été modifiée avec succès',
      null,
    );

    this.loadData();
    return Promise.resolve();
  };

  deleteAssociation = async (ruleAssociation) => {
    this.props.layoutContextProvider.showLoader();
    let error = false;

    await ManagementRulesApi.deleteAssociation(
      this.props.clinicId,
      ruleAssociation.externalId,
    ).catch((e) => {
      error = true;
      ApiErrorsHelper.manage(e);
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success(
        'La règle de gestion a été supprimée avec succès',
        null,
      );
      this.loadData();
    }
  };

  render() {
    return (
      <div className="protocolsTab">
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement des règles de gestion"
          loadingError={this.state.loadingError}>
          {this.state.managementRules && this.renderContent()}
        </PageDataLoader>
      </div>
    );
  }

  renderContent = () => {
    return (
      <div className="protocolsTab">
        <FormGroup className="text-end">
          <CreateButtonModal
            text="Nouvelle règle de gestion"
            onSubmit={this.saveAddModal}>
            <CreateForm
              treatmentModes={this.state.treatmentModes}
              protocols={this.state.protocols}
              managementRules={this.state.ruleModels}
            />
          </CreateButtonModal>
        </FormGroup>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Mode de traitement</th>
              <th>Protocole Ambulis</th>
              <th>Règle de gestion</th>
              <th>Paramètres</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.managementRules.map((rule) => {
              let treatmentMode = null;
              let ambulisProtocol = null;

              const ruleModel = this.state.ruleModels.find(
                (r) => r.code === rule.ruleCode,
              );

              if (rule.mdtCode) {
                treatmentMode = this.state.treatmentModes.find(
                  (tm) => tm.mdtCode === rule.mdtCode,
                );
              }

              if (rule.ambulisOperatingMethodId) {
                ambulisProtocol = this.state.protocols.find(
                  (p) => p.id === rule.ambulisOperatingMethodId,
                );
              }

              return (
                <tr key={rule.ruleCode}>
                  <td>{treatmentMode ? treatmentMode.name : 'Tous'}</td>
                  <td>{ambulisProtocol ? ambulisProtocol.name : 'Tous'}</td>
                  <td>{ruleModel.title}</td>
                  <td>
                    <ParamsDetails
                      ruleAssociation={rule}
                      ruleModel={ruleModel}
                    />
                  </td>
                  <td className="text-center width-auto">
                    <EditButton
                      ruleAssociation={rule}
                      ruleModel={ruleModel}
                      treatmentMode={treatmentMode}
                      ambulisProtocol={ambulisProtocol}
                      onSubmit={this.saveEditModal}
                    />
                    <DeleteButton
                      title={<span>Confirmation de suppression</span>}
                      data={rule}
                      onConfirm={this.deleteAssociation}
                      className="ms-1">
                      Voulez-vous réellement supprimer cette règle de gestion ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };
}
